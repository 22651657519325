import { FC } from 'react';
import './featureSelection.css';

interface FeatureSelectionProps {
    featureList: string[];
    activeIndex: number;
    callback: (index: number) => void;
}

const FeatureSelection: FC<FeatureSelectionProps> = ({ featureList, activeIndex, callback }) => {

    return (
        <div className='features-selection'>
            {featureList.map((feature, index) => (
                <div 
                    key={index} 
                    onClick={() => callback(index+1)} 
                    className={activeIndex === index+1 ? 'single-feature-selection active-selection' : 'single-feature-selection'}>
                    <p>{feature}</p>
                </div>
            ))}
        </div>
    );
};

export default FeatureSelection;