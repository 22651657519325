import { FC, useState } from 'react';
import './resetPassword.css'; 
import { useParams } from 'react-router';
import checkSvg from '../../Assets/svgs/check.svg';
import { CustomHttpResponse, apiClient } from '../../services/APIClient';
import Navbar from '../navBar/Navbar';

const ResetPassword: FC = () => {
    const { token } = useParams<{ token: string }>();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      if (password.length < 8) {
        setError('Password should be at least 8 characters');
        return;
      }
      try {
        const response = await apiClient.put<CustomHttpResponse>(`/users/verify-email`, undefined, {token});
        if (response.status !== 200) {
            setSuccess(false);
            setError("Reset password failed. Please try again.")
            return;
        }

        setSuccess(true);
    } catch (error: any) {
        setSuccess(false);
    }
    };    

    const renderBox = () => {
        if (success) {
            return (
                <div className='centered-verification'>
                    <img src={checkSvg} alt="Check mark" width={150} />
                    <h1>Password Changed</h1>
                    <p>Your password has changed successfully</p>
                </div>         
            );
        } else {
            return (
                <div>
                    <div className="reset-password-content">
                        <h1>Reset Your Password</h1>
                        <p>Forgot your password. Re-enter your password here</p>
                    </div>
                    {error && <p className='reset-password-error'> {error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className='reset-password-input'>
                            <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='New password...'
                            required
                            />
                        </div>
                        <div className='reset-password-input'>
                            <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder='Verify password...'
                            required
                            />
                        </div>
                        <button type="submit">Reset Password</button>
                    </form>
                </div>
            );
        }
    }
    
    return (
        <div className='content-for-user-action'>
            <Navbar/>
            <div className="reset-password-box">
                {renderBox()}
            </div>
        </div>
    );
}

export default ResetPassword;