import React, { FC, useEffect, useRef } from 'react';
import './homeSection.css';
import Navbar from '../../../navBar/Navbar';
import { InputGroup, Button, Form } from 'react-bootstrap';
import invokeCTA from '../../../../services/emailJS.service';
import useIntersectionObserver from '../../../../services/intersectionObserver';

const Home: FC = () => {
    const [userInput, setUserInput] = React.useState<string>('');
    const [divKey, setDivKey] = React.useState<number>(0);
    const animationTriggered = useRef(false);
    const divRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(divRef);

    useEffect(() => {
        if (isIntersecting && !animationTriggered.current) {
            setDivKey(prevKey => prevKey + 1);
            animationTriggered.current = true;
          }
    }
    , [isIntersecting, divKey]);
    
    return (
        <div id="home-section" className="home landing-page-section">
            <Navbar />
            <div key={divKey} ref={divRef} className="centered-home-section">
                <div className="left-side-home-section">
                    <h1>
                        WELCOME TO DARB!
                    </h1>
                    <h2>
                        EXPLORE. EXPERIENCE. ESCAPE.
                    </h2>
                    <p>
                    A user-centric platform for outdoor enthusiasts of all levels to discover trails in the United Arab Emirates (UAE) based on their preferences whether its distance, elevation, difficulty, duration and much more.                    </p>
                    <div className="left-side-home-section-cta">
                        <InputGroup className="home-section-cta-input">
                            <Form.Control
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                value={userInput}
                                placeholder="Enter your email"
                                onChange={(e) => setUserInput(e.target.value)}
                            />
                        </InputGroup>
                        <Button className='home-cta-button' onClick={() => invokeCTA(userInput)}>
                            <h6>
                                Get Early Access
                            </h6>
                        </Button>
                    </div>
                </div>
                <div className="right-side-home-section">
                    <img src={require('../../../../Assets/homeLogo.png')} alt="Darb Logo" width={'350px'}/>
                </div>
            </div>
        </div>
    );
}

export default Home;