import { FC, useEffect, useRef } from 'react';
import './ourStory.css';
import React from 'react';
import useIntersectionObserver from '../../../../services/intersectionObserver';


const OurStory: FC = () => {

    const [divKey, setDivKey] = React.useState<number>(0);
    const animationTriggered = useRef(false);
    const divRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(divRef, {threshold: 0.1});

    useEffect(() => {
        if (isIntersecting && !animationTriggered.current) {
            setDivKey(prevKey => prevKey + 1);
            animationTriggered.current = true;
          }
    }
    , [isIntersecting, divKey]);


    return (
        <div id="our-story-section" className="our-story landing-page-section">
            <div key={divKey} ref={divRef} className="centered-our-story-section">
                <h1>ABOUT US</h1>
                <p>
                    We believe that the breathtaking beauty of the MENA Region’s nature and landscapes should be accessible and enjoyed by everyone. That’s why we’ve developed Darb – the ultimate hiking app designed to transform outdoor adventures throughout the region. Our goal is provide a comprehensive and safe experience that caters to the diverse needs of our local community.
                </p>
                <br/>
                <p>
                    Darb is the result of a collective passion for adventure and a profound admiration for the natural wonders of the MENA Region. Whether you’re an experienced trekker or just starting your exploration journey, Darb is your trusted companion, ready to lead you on unforgettable adventures through awe-inspiring landscapes.                
                </p>
            </div>
        </div>
    );
}

export default OurStory;