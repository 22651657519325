import emailjs from "@emailjs/browser";


export default async function invokeCTA(input: string) {
    // 
    emailjs.init("g1mu1VapDJ5VLx9yR");
    const serviceId = "service_q7ou9g5";
    const templateId = "template_dpzlmro";
    try {
      await emailjs.send(serviceId, templateId, {
       message: input,
      });
      alert("email successfully sent check inbox");
    } catch (error) {
      console.log(error);
    }
}