import { FC } from 'react';
import './featuresDescription.css';
interface Props {
    header: string;
    paragraph: string;
}

const FeatureDescription: FC<Props> = ({ header, paragraph }) => {

    return (
        <div className='features-description'>
            <div className="features-title">
                <div className="orange-line" />
                <h4> READY TO EXPLORE?</h4>
            </div>
            <h1>{header}</h1>
            <p>{paragraph}</p>
        </div>
    );

}

export default FeatureDescription;