import { FC, useEffect, useRef, useState } from 'react';
import './featuresSection.css';
import FeatureSelection from './components/featureSelection/featureSelection';
import FeatureDisplay from './components/featureDisplay/featureDisplay';
import FeatureDescription from './components/featureDescription/featureDescription';
import React from 'react';
import useIntersectionObserver from '../../../../services/intersectionObserver';

const featureList: string[] = [
    'Explore',
    'Filtering',
    'Trail Data',
    'Navigation',
    'My Trails',
];

const headersArray = [
    'EXPLORE DARB & WHAT IT HAS TO OFFER', 
    'COMPREHENSIVE TRAIL DIRECTORY', 
    'TRAIL FILTERING', 
    'COMPREHENSIVE TRAIL DATA', 
    'TRAIL NAVIGATION', 
    'MY TRAILS'
]
const paragraphsArray = [
    '',
    'Access an extensive database of hiking trails across the MENA region, featuring detailed information such as trail names, difficulty levels, distances, durations, and more. Explore the diverse landscapes and choose the perfect adventure tailored to your preferences.',
    'You can narrow down your search for the ideal adventure with our search and filter tools. Tailor your trail selection based on your preferences, ensuring you find the perfect outdoor experience that suits your interests and abilities.',
    'Discover the depths of your chosen adventure with comprehensive trail information at your fingertips. Uncover detailed trail descriptions, starting points, captivating photos, crucial waypoints, a curated list of recommended gear, insightful user reviews, and any additional details essential for safe and effective trip planning.',
    'Experience seamless navigation along the trail with precise location tracking and progress monitoring. Even in areas with no cellular network coverage, Darb allows you to download the trail beforehand, ensuring uninterrupted and accurate navigation from the moment you set foot on the trail.',
    'You can save your favorite trails for easy access. Keep track of trails you\'ve conquered and seamlessly access downloaded trails, ensuring you\'re always prepared for your next outdoor escapade.',
]
const Features: FC = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [divKey, setDivKey] = React.useState<number>(0);
    const animationTriggered = useRef(false);
    const divRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(divRef);

    useEffect(() => {
        if (isIntersecting && !animationTriggered.current) {
            setDivKey(prevKey => prevKey + 1);
            animationTriggered.current = true;
          }
    }
    , [isIntersecting, divKey]);

    return (
        <div id="features-section" className="features landing-page-section">
            <div key={divKey} ref={divRef} className="centered-features-section">
                <h1 className="features-header">
                    OUR FEATURES
                </h1>
                <div className="feature-select-and-mockup">
                    <FeatureSelection featureList={featureList}  callback={setActiveIndex} activeIndex={activeIndex}/>
                    <FeatureDisplay key={activeIndex} index={activeIndex}/>
                </div>
                <div className="feature-desc">
                    <FeatureDescription key={activeIndex} header={headersArray[activeIndex]} paragraph={paragraphsArray[activeIndex]} />
                </div>
            </div>
        </div>
    );
}

export default Features;