import { FC } from 'react';
import './verifyEmail.css'; 
import { useParams } from 'react-router';
import { useConfirmEmail } from '../../hooks/useConfirmEmail.hook';
import checkSvg from '../../Assets/svgs/check.svg';
import Navbar from '../navBar/Navbar';

const VerifyEmail: FC = () => {
    const { token } = useParams<{ token: string }>();
    const { isLoading, error } = useConfirmEmail(token || '');

    return (
        <div className='content-for-user-action'>
            <Navbar />
            <div className='centered-verification'>
            {isLoading && <h1>Confirming your email...</h1>}
            {
            !isLoading && !error &&
                <div className='centered-verification'>
                    <img src={checkSvg} alt="Check mark" />
                    <h1>Verification Complete</h1>
                    <h3>Your email address has been verified</h3>
                </div>         
            }
            {error && (
                <div style={{color: 'black'}}>
                    <h1>Oops!</h1>
                    <h3>There was an error confirming your email {error}</h3>
                </div>
            )} 
            </div>
        </div>
    );
}

export default VerifyEmail;