import { FC } from 'react';
import './featureDisplay.css';
interface ImageProps {
    index: number;
}

const FeatureDisplay: FC<ImageProps> = ({ index }) => {

    return (
        <img src={require(`../../../../../../Assets/appMockups/${index}.png`)} alt="Darb Mockups" className='feature-image-mockup'/>    
    );
}

export default FeatureDisplay;