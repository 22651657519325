import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import{
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons"

export default function Footer() {
    return (
    <footer className="footer">
        <text className="CompanyName">CompanyName @ 202X. All rights reserved.</text>
        <ul>
            <a href="https://www.youtube.com/watch?v=hfvoJ3Bd-9E" className="youtube" target="_blank"><FontAwesomeIcon icon={faYoutube} /></a>
            <a href="https://facebook.com" className="facebook" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
            <a href="https://twitter.com/" className="twitter" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://instagram.com" className="instagram" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://linkedin.com" className="linkedin" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
        </ul>
    </footer>
    )
}