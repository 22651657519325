import "./Navbar.css"
import headerLogoSVG from '../../Assets/svgs/headerLogo.svg';
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from "react-router-dom";


export default function Header() {


    return (
        <>
            <Navbar expand='lg' >
                <Container className="darb-navbar">
                    <Navbar.Brand href="/#home">
                        <img src={headerLogoSVG} alt="Darb Logo" className="svg-icon darb-logo-header" />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    {<Navbar.Collapse className="justify-content-end">
                        <Nav.Link className="headerLink" href="/#story" >
                            <ScrollLink to="our-story-section" smooth={true} duration={100}>
                                Our Story
                            </ScrollLink>
                        </Nav.Link>
                        <Nav.Link as={RouterLink} className="headerLink" to="/hehe">
                            <ScrollLink to="features-section" smooth={true} duration={100}>
                                Features
                            </ScrollLink>
                        </Nav.Link>
                        <Nav.Link className="headerLink orange-header-cta">
                            <ScrollLink to="get-started-now-section" smooth={true} duration={100}>
                                Get Early Access
                            </ScrollLink>
                        </Nav.Link>
                    </Navbar.Collapse>}
                </Container>
            </Navbar>
        </>
    )
}