import { useEffect, useState } from "react";
import { CustomHttpResponse, apiClient } from "../services/APIClient";

interface ConfirmEmailState {
    message: string;
    isLoading: boolean;
    error: string | null;
}

export const useConfirmEmail = (token: string) => {
    const [state, setState] = useState<ConfirmEmailState>({
        message: '',
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await apiClient.put<CustomHttpResponse>(`/users/verify-email`, undefined, {token});

                if (response.status !== 200) {
                    throw new Error('Email confirmation failed');
                }

                const data = response.data;
                setState({ message: data.message, isLoading: false, error: null });
            } catch (error: any) {
                setState({ message: '', isLoading: false, error: error.message });
            }
        };

        confirmEmail();
    }, [token]);

    return state;
};