import React, { FC } from 'react';
import './homePage.css';  
import Home from './Sections/Home/home.section';
import OurStory from './Sections/OurStory/ourStory';
import Features from './Sections/Features/features.sections';
import GetStartedNow from './Sections/GetStartedNow/getStartedNow.section';

const HomePage: FC = () => {
    return (
        <div className="App">
            <header className="">
                <Home />
                <OurStory />
                <Features />
                <GetStartedNow />
            </header>
        </div>
    );
}

export default HomePage;