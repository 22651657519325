import React, { FC, useEffect, useRef } from 'react';
import './getStartedNowSection.css';
import { InputGroup, Button, Form } from 'react-bootstrap';
import invokeCTA from '../../../../services/emailJS.service';
import useIntersectionObserver from '../../../../services/intersectionObserver';

const GetStartedNow: FC = () => {

    const [userInput, setUserInput] = React.useState<string>('');
    const [divKey, setDivKey] = React.useState<number>(0);
    const animationTriggered = useRef(false);
    const divRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(divRef);

    useEffect(() => {
        if (isIntersecting && !animationTriggered.current) {
            setDivKey(prevKey => prevKey + 1);
            animationTriggered.current = true;
          }
    }
    , [isIntersecting, divKey]);

    return (
        <div  id="get-started-now-section" className="get-started-now landing-page-section">
            <div key={divKey} ref={divRef} className="centered-get-started-now-section">
                <div className="left-side-get-started-now-section">
                    <h1>
                        COMING SOON!!
                    </h1>
                    <h2>
                        TO BE RELEASED SOON ON APP STORE!
                    </h2>
                    <p>
                    Embark on a safe outdoor adventure with Darb as your guide. Explore the beauty of the MENA Region's nature while ensuring a secure and delightful experience.                    </p>
                    <div className="left-side-get-started-now-section-cta">
                        <InputGroup className="get-started-now-section-cta-input">
                            <Form.Control
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                value={userInput}
                                placeholder="Enter your email"
                                onChange={(e) => setUserInput(e.target.value)}
                            />
                        </InputGroup>
                        <Button className='get-started-now-cta-button' onClick={() => invokeCTA(userInput)}>
                            <h6>
                                Get Early Access
                            </h6>
                        </Button>
                    </div>
                </div>
                <div className="right-side-get-started-now-section">
                    <img src={require('../../../../Assets/getStartedNowMockup.png')} alt="Darb Mockups" width={'350px'}/>
                </div>
            </div>
        </div>
    );
}

export default GetStartedNow;